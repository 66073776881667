import dayjs from 'dayjs';
import { useMemo } from 'react';

import { getRouteById } from 'Global/JourneyElements/Utils/getRouteById';
import useCheckInStore from 'Hooks/useCheckInStore';
import StopEntity from 'Models/Entities/StopEntity';
import TripStopEntity from 'Models/Entities/TripStopEntity';
import { getCalendarDays } from 'Util/_HumanWritten/DateUtils';

interface StopSelection {
	stop: StopEntity;
	departureDate: string;
	departureTime: string;
	arrivalTime: string;
	arrivalExtraDays: string;
}

/**
 * Uses CheckInContext.
 */
export const useCheckInStopSelections = () => {
	const {
		ferryTrip: { routeId, tripStops },
	} = useCheckInStore();

	const stopSelections = useStopSelections(routeId, tripStops);

	return { stopSelections };
};

const useStopSelections = (routeId: string, tripStops: TripStopEntity[]): StopSelection[] => {
	const route = getRouteById(routeId);

	const stopSelections = useMemo(() => {
		if (!route?.stops) {
			return [];
		}

		return route.stops.map(stop => {
			const tripStop = tripStops.find(ts => ts.stopId === stop.id);

			let departureDate = '';
			let departureTime = '';
			let arrivalTime = '';
			let arrivalExtraDays = '';

			if (tripStop) {
				const departureDateTime = dayjs(tripStop.departureDateTime);
				departureDate = departureDateTime.format('DD-MMM');
				departureTime = departureDateTime.format('hh:mm');

				const upcomingSegment = stop.startSegments.find(ss => ss.startStopId === stop.id);
				if (upcomingSegment) {
					const arrivalDateTime = departureDateTime.add(upcomingSegment.durationInMinutes, 'minutes');
					arrivalTime = arrivalDateTime.format('hh:mm');

					const calendarDays = getCalendarDays(departureDateTime.toDate(), arrivalDateTime.toDate());
					if (calendarDays > 0) {
						arrivalExtraDays = `+${calendarDays}`;
					}
				}
			}

			return {
				stop,
				departureDate,
				departureTime,
				arrivalTime,
				arrivalExtraDays,
			};
		});
	}, [route, tripStops]);

	return stopSelections;
};
