import React from 'react';

import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import LinkAsButton from 'Views/Components/_HumanWritten/LinkAsButton/LinkAsButton';
import { Colors, Display } from 'Views/Components/Button/Button';

export default function CheckInSummaryTopNav() {
	return (
		<div className="check-in-summary__topnav">
			<LinkAsButton
				to="/ferry-schedule"
				display={Display.Text}
				colors={Colors.Black}
				className="check-in-summary__topnav__back-btn"
			>
				<Icon name="chevron-left" />
				<div>Back</div>
			</LinkAsButton>
		</div>
	);
}
