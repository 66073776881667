import { useEffect } from 'react';

import { useCheckInFilterStore } from '../../Hooks/useCheckInFilterStore';
import { useCheckInStopSelections } from './useCheckInStopSelections';

const DISEMBARK_TAB_INDEX = 0;
const EMBARK_TAB_INDEX = 1;

export const useCheckInSummaryTabIndex = () => {
	const embarkation = useCheckInFilterStore(x => x.embarkation);
	const setState = useCheckInFilterStore(x => x.setState);
	const { selectedStopId } = useCheckInFilterStore();
	const { stopSelections } = useCheckInStopSelections();

	const disembarkDisabled = stopSelections.findIndex(x => x.stop.id === selectedStopId) === 0;
	const embarkDisabled = stopSelections.findIndex(x => x.stop.id === selectedStopId) === stopSelections.length - 1;
	const tabIndex = (() => {
		if (disembarkDisabled || embarkDisabled) {
			return 0;
		}
		if (embarkation === 'embark') {
			return EMBARK_TAB_INDEX;
		}
		return DISEMBARK_TAB_INDEX;
	})();

	useEffect(() => {
		if (disembarkDisabled) {
			setState({ embarkation: 'embark' });
		}
		if (embarkDisabled) {
			setState({ embarkation: 'disembark' });
		}
	}, [disembarkDisabled, embarkDisabled]);

	return {
		tabIndex,
		onTabClicked: (index: number) => {
			if (disembarkDisabled) {
				setState({ embarkation: 'embark' });
				return;
			}
			if (embarkDisabled) {
				setState({ embarkation: 'disembark' });
				return;
			}
			if (index === DISEMBARK_TAB_INDEX) {
				setState({ embarkation: 'disembark' });
			}
			if (index === EMBARK_TAB_INDEX) {
				setState({ embarkation: 'embark' });
			}
		},
		disembarkDisabled,
		embarkDisabled,
	};
};
