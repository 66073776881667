import React from 'react';

import { getRouteById } from 'Global/JourneyElements/Utils/getRouteById';

interface CheckInSummaryRouteNavProps {
	routeId: string;
	voyageNumber?: string;
}

export default function CheckInSummaryRouteNav({ routeId, voyageNumber }: CheckInSummaryRouteNavProps) {
	const route = getRouteById(routeId);

	if (!route) {
		return <></>;
	}

	return (
		<div className="check-in-summary__second-nav">
			<div className="check-in-summary__second-nav__title">{route.name}</div>
			{!!voyageNumber && <div className="check-in-summary__second-nav__subtitle">{voyageNumber}</div>}
		</div>
	);
}
