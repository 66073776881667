import React from 'react';

import { useCheckInRoutes } from 'Hooks/useCheckInRoutes';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import { Button, Colors, Display } from 'Views/Components/Button/Button';
import Tabs, { ITabConfig } from 'Views/Components/Tabs/Tabs';
import CapacityTabContent from './CheckInCapacity/CapacityTabContent';
import { useCheckInSummaryTabIndex } from './Hooks/useCheckInSummaryTabIndex';

function CheckInSummaryTabLayout({ children }: { children?: React.ReactNode }) {
	return <div className="check-in-summary__tab-content">{children}</div>;
}

interface CheckInSummaryTabsProps {}

export default function CheckInSummaryTabs(props: CheckInSummaryTabsProps) {
	// eslint-disable-next-line object-curly-newline
	const { tabIndex, onTabClicked, disembarkDisabled, embarkDisabled } = useCheckInSummaryTabIndex();

	const disembarkTab: ITabConfig = {
		key: 'disembark',
		name: 'DISEMBARK',
		component: <DisembarkTabContent />,
	};

	const embarkTab: ITabConfig = {
		key: 'embark',
		name: 'EMBARK',
		component: <EmbarkTabContent />,
	};

	const tabs = React.useMemo(() => {
		const results: ITabConfig[] = [];
		if (!disembarkDisabled) {
			results.push(disembarkTab);
		}
		if (!embarkDisabled) {
			results.push(embarkTab);
		}
		return results;
	}, [disembarkDisabled, embarkDisabled]);

	return <Tabs className="check-in-summary__tabs" currentTab={tabIndex} onTabClicked={onTabClicked} tabs={tabs} />;
}

function DisembarkTabContent() {
	const { goToBase } = useCheckInRoutes();

	return (
		<>
			<CheckInSummaryTabLayout>
				<CapacityTabContent />
			</CheckInSummaryTabLayout>
			<div className="check-in-summary__btn-actions-row">
				<Button
					className="check-in-summary__btn"
					display={Display.Solid}
					colors={Colors.Secondary}
					onClick={goToBase}
				>
					{/* TODO : Use white label text */}
					Start disembarking
					<Icon name="upload" />
				</Button>
				<Button
					className="check-in-summary__btn"
					display={Display.Solid}
					colors={Colors.Primary}
					onClick={goToBase}
				>
					Review
				</Button>
			</div>
		</>
	);
}

function EmbarkTabContent() {
	const { goToBase } = useCheckInRoutes();

	return (
		<>
			<CheckInSummaryTabLayout>
				<CapacityTabContent />
			</CheckInSummaryTabLayout>
			<div className="check-in-summary__btn-actions-row">
				<Button
					className="check-in-summary__btn"
					display={Display.Solid}
					colors={Colors.Black}
					onClick={goToBase}
				>
					{/* TODO : Use white label text */}
					Start embarking
					<Icon name="download" />
				</Button>
				<Button
					className="check-in-summary__btn"
					display={Display.Solid}
					colors={Colors.Primary}
					onClick={goToBase}
				>
					Review
				</Button>
			</div>
		</>
	);
}
