import React from 'react';
import { observer } from 'mobx-react';

import useCheckInStore from 'Hooks/useCheckInStore';
import { checkedInVehicleBookings, totalVehicleBookings } from '../../CheckInUtils';
import { checkInStore } from '../../context/CheckInContext';
import { useCheckInFilterStore } from '../../Hooks/useCheckInFilterStore';
import CheckInCapactiySection from './CheckInCapacitySection';

function CheckInCargoCapacitySection() {
	const {
		ferryTrip: {
			ferry: { vehicleCapacity },
		},
	} = useCheckInStore();

	const selectedStopId = useCheckInFilterStore(x => x.selectedStopId);

	const { bookings } = checkInStore;

	const filteredBookings = bookings.filter(x => {
		if (x.bookedSummary.startStopId === selectedStopId) {
			return true;
		}
		if (x.bookedSummary.endStopId === selectedStopId) {
			return true;
		}
		return false;
	});

	const checkedInCargoSpace = checkedInVehicleBookings(filteredBookings);
	const bookedCargoSpace = totalVehicleBookings(filteredBookings);

	return (
		<CheckInCapactiySection
			icon="custom-vehicle-dark"
			title="Vehicles and trailers" // TODO: use whitelabel text
			totalCapacity={vehicleCapacity}
			segmentValue={checkedInCargoSpace}
			segmentCapacity={bookedCargoSpace}
		/>
	);
}

export default observer(CheckInCargoCapacitySection);
