import { useEffect } from 'react';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

import { getRouteById } from 'Global/JourneyElements/Utils/getRouteById';
import { useSearchParams } from 'Hooks/useSearchParams';

const EMBARKATION_PARAM = 'embarkation';
const STOP_ID_PARAM = 'stopId';

interface CheckInFilterStoreState {
	selectedStopId: string;
	embarkation?: 'embark' | 'disembark';
}

interface CheckInFilterStoreActions {
	setState(state: Partial<CheckInFilterStoreState>): void;
}

type CheckInFilterStore = CheckInFilterStoreState & CheckInFilterStoreActions;

export const useCheckInFilterStore = create(
	persist<CheckInFilterStore>(
		(set, get) => ({
			selectedStopId: '',
			setState(state) {
				set(state);
			},
		}),
		{
			name: 'check-in-multi-stop-store', // name of the item in the storage (must be unique)
			storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
		},
	),
);

/**
 * CAUTION: Should only be used in the root component of a feature (e.i. page component).
 *
 * Syncs url search param for selected stop id with the zustand state.
 * It also ensures the stop is valid for the given route.
 */
export const useCheckInFilterSelectedStopSearchParams = (routeId?: string) => {
	const { getSearchParam, setSearchParam } = useSearchParams();
	const { selectedStopId, setState } = useCheckInFilterStore();

	// Initial read from url
	useEffect(() => {
		const stopId = getSearchParam(STOP_ID_PARAM) || '';
		setState({ selectedStopId: stopId });
	}, []);

	// Sync check in filter state with the URL
	useEffect(() => {
		if (routeId) {
			setSearchParam(STOP_ID_PARAM, selectedStopId);
		}
	}, [selectedStopId]);

	// Sync and validate initial check in filter state
	useEffect(() => {
		if (routeId) {
			const route = getRouteById(routeId);
			if (!route) {
				throw new Error(`Missing route with id: ${routeId}`);
			}
			if (route.stops.find(x => x.id === selectedStopId)) {
				setState({ selectedStopId });
				return;
			}
			setState({ selectedStopId: route.stops[0].id });
		}
	}, [routeId]);
};

export const useCheckInFilterEmbarkationSearchParams = (routeId?: string) => {
	const { getSearchParam, setSearchParam } = useSearchParams();
	const { embarkation, setState } = useCheckInFilterStore();

	// Initial read from url
	useEffect(() => {
		const embarkationParam = getSearchParam(EMBARKATION_PARAM) || '';
		if (embarkationParam === 'embark' || embarkationParam === 'disembark') {
			setState({ embarkation: embarkationParam });
		}
	}, []);

	// Sync check in filter state with the URL
	useEffect(() => {
		if (embarkation) {
			setSearchParam(EMBARKATION_PARAM, embarkation);
		}
	}, [embarkation]);
};
