import React from 'react';

import Icon from 'Views/Components/_HumanWritten/Icon/Icon';

interface CheckInCapacitySectionProps {
	icon?: string;
	title?: string;
	totalCapacity?: number | string;
	segmentCapacity?: number | string;
	segmentValue?: number | string;
}

export default function CheckInCapactiySection({
	icon = 'package',
	title = 'Packages',
	totalCapacity = 411,
	segmentCapacity = 15,
	segmentValue = 88,
}: CheckInCapacitySectionProps) {
	const barWidthPercent = React.useMemo(() => {
		if (!(typeof segmentValue === 'number' && typeof segmentCapacity === 'number')) {
			return '0%';
		}
		const percent = (100 * segmentValue) / segmentCapacity;
		if (percent > 100) {
			return '100%';
		}
		return `${percent}%`;
	}, [segmentCapacity, segmentValue]);

	return (
		<div className="check-in-summary__capacity-section">
			<div className="check-in-summary__capacity-title">
				<Icon name={icon} />
				<div>{title}</div>
			</div>
			<div className="check-in-summary__capacity-row">
				<div className="check-in-summary__capacity-info">
					<div className="check-in-summary__capacity-value">{totalCapacity}</div>
					<div className="check-in-summary__capacity-label">Max capacity</div>
				</div>
				<div className="check-in-summary__capacity-info">
					<div className="check-in-summary__capacity-value">
						{segmentValue}/{segmentCapacity}
					</div>
					{/* <div className="check-in-summary__capacity-label">Space capacity (checked-in / booked)</div> */}
					<div className="check-in-summary__capacity-label">Checked-in</div>
					<div className="check-in-summary__capacity-bar-container" />
					<div className="check-in-summary__capacity-bar" style={{ width: barWidthPercent }} />
				</div>
			</div>
		</div>
	);
}
