import * as React from 'react';
import Collection from 'Views/Components/Collection/Collection';
import { ICollectionHeaderProps } from 'Views/Components/Collection/CollectionHeaders';
import { observer } from 'mobx-react';
import { IOrderByCondition } from 'Views/Components/ModelCollection/ModelQuery';
import useStore from 'Hooks/useStore';
import { AllCheckedIn } from '../CheckInEmpty/AllCheckedIn';
import { CheckInOrUndoButton } from '../CheckInButtons/CheckInOrUndoButton';
import useCheckInStore from 'Hooks/useCheckInStore';
import { NoneCheckedIn } from '../CheckInEmpty/NoneCheckedIn';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import usePassengerTypes from 'Hooks/usePassengerTypes';
import passengerTypeStore from 'Models/PassengerTypeStore';
import { CheckInOpenClose } from '../CheckInList/CheckInOpenClose';
import { CheckInBookingOverviewDto } from '../CheckInEntities/CheckInBookingOverviewDto';
import { isNotNullOrUndefined, stringIsEmpty } from 'Util/TypeGuards';
import Icon from 'Views/Components/_HumanWritten/Icon/Icon';
import { confirmModal } from 'Views/Components/Modal/ModalUtils';
import { PlayCheckInAudio } from 'Views/Components/_HumanWritten/AudioPlayer/AudioPlayer';
import alertToast from 'Util/ToastifyUtils';
import { useEffect, useState } from 'react';
import If from 'Views/Components/If/If';
import { CheckInFilters } from '../CheckInFilters';
import { CheckInSearch } from '../CheckInSearch';
import { ScrollToItem } from 'Validators/Functions/HumanWritten/ScrollToElement';
import { filterFerryBookings } from '../Helpers/FilterFerryBookings';
import { CheckInSearchEmpty } from './CheckInSearchEmpty';
import { BookingEntity } from 'Models/Entities';
import {
	CheckInSorter,
	firstLetterLastName, getCheckInTableHeader, sortByCar,
	sortByFullName,
	sortByRego,
	sortByTrailer,
	sortByVehicleType,
	transformCheckInStatus,
	transformFullName,
	transformMobileNumber,
	transformPassengers,
	transformRego,
	transformTrailerSpace,
	transformVehicleMakeModel,
	transformVehicleSpace,
} from '../CheckInUtils';
import {
	CheckInCountType,
	useFilterAddOn,
	useSearchTerm,
	useSortSettings,
} from '../CheckInView';
import { GetModalContentForCheckIn } from '../Modals/CheckInModalHelpers';
import { CheckInBookingConfirmationModal } from '../Modals/CheckInBookingConfirmationModal';

export interface CheckInTableProps {
	isVehicle: boolean;
	/**
	 * True displays bookings for check in, false displays checked-in booking and undo buttons.
	 */
	forCheckIn?: boolean;
	startStopId?: string;
}

function CheckInTable(props: CheckInTableProps) {
	const {
		isVehicle,
		forCheckIn = true,
		startStopId,
	} = props;

	const passengerTypes = usePassengerTypes();
	const store = useStore();
	const checkInStore = useCheckInStore();
	const { ferryTripId, bookings, isOffline } = checkInStore;
	const showCheckInOpenClose = !isOffline();

	const {
		countType,
		cargoSorting,
		cargoDescending,
		passengerSorting,
		passengerDescending,
		setCountType,
		setCargoSorting,
		setPassengerSorting,
		setCargoDescending,
		setPassengerDescending,
		toggleCargoDescending,
		togglePassengerDescending,
	} = useSortSettings(x => ({
		countType: x.countType,
		setCountType: x.setCountType,
		cargoSorting: x.cargoSorting,
		cargoDescending: x.cargoDescending,
		passengerSorting: x.passengerSorting,
		passengerDescending: x.passengerDescending,
		setCargoSorting: x.setCargoSorting,
		setPassengerSorting: x.setPassengerSorting,
		setCargoDescending: x.setCargoDescending,
		setPassengerDescending: x.setPassengerDescending,
		toggleCargoDescending: x.toggleCargoDescending,
		togglePassengerDescending: x.togglePassengerDescending,
	}));

	const {
		addOnsModel,
	} = useFilterAddOn(x => ({
		addOnsModel: x.model,
	}));

	const {
		model,
	} = useSearchTerm(x => ({
		model: x.model,
	}));

	const [refresh, setRefresh] = useState<number>(1);

	useEffect(() => {
		ScrollToItem('.collection__list');
	}, []);

	const onSortClicked = (headerName: string, ascendingSorter: CheckInSorter, descendingSorter: CheckInSorter) => {
		const sorting = isVehicle ? cargoSorting : passengerSorting;
		const setDescending = isVehicle ? setCargoDescending : setPassengerDescending;
		const toggleDescending = isVehicle ? toggleCargoDescending : togglePassengerDescending;
		if (sorting === ascendingSorter || sorting === descendingSorter) {
			toggleDescending();
		} else {
			setDescending(false);
		}

		const updatedState = useSortSettings.getState();
		if (isVehicle) {
			setCargoSorting(updatedState.cargoDescending ? descendingSorter : ascendingSorter);
		} else {
			setPassengerSorting(updatedState.passengerDescending ? descendingSorter : ascendingSorter);
		}

		return {
			path: headerName,
			descending: isVehicle ? updatedState.cargoDescending : updatedState.passengerDescending,
		};
	};

	const headers = React.useMemo(() => {
		let result: ICollectionHeaderProps<CheckInBookingOverviewDto>[] = [
			{
				name: 'check-in-status',
				className: 'check-in-status-col',
				displayName: ' ',
				transformItem: transformCheckInStatus,
			},
			{
				name: 'name',
				displayName: 'Name',
				transformItem: (item: CheckInBookingOverviewDto | BookingEntity) => transformFullName(
					item,
					true,
				),
				sortable: true,
				nullValue: '-',
				sortClicked: (): IOrderByCondition<CheckInBookingOverviewDto> => {
					return onSortClicked(
						'name',
						CheckInSorter.Fullname,
						CheckInSorter.FullnameDesc);
				},
			},
			{
				name: 'phone',
				displayName: 'Mobile',
				transformItem: transformMobileNumber,
				nullValue: '-',
			},
			{
				name: CheckInSorter.VehicleType,
				displayName: `${whiteLabelStore.vehicleLabelPascalCase} type`,
				transformItem: transformVehicleMakeModel,
				sortable: true,
				sortClicked: (): IOrderByCondition<CheckInBookingOverviewDto> => {
					return onSortClicked(
						CheckInSorter.VehicleType,
						CheckInSorter.VehicleType,
						CheckInSorter.VehicleTypeDesc);
				},
			},
			{
				name: CheckInSorter.Rego,
				className: 'vehicle-rego-col',
				displayName: whiteLabelStore.regoLabelPascalCase,
				transformItem: transformRego,
				sortable: true,
				sortClicked: (): IOrderByCondition<CheckInBookingOverviewDto> => {
					return onSortClicked(
						CheckInSorter.Rego,
						CheckInSorter.Rego,
						CheckInSorter.Rego);
				},
			},
			{
				name: CheckInSorter.Vehicle,
				cellClassName: 'vehicle-length-cell',
				customDisplayName: <div className={whiteLabelStore.vehicleIconDarkClassName} />,
				displayName: '',
				transformItem: (item: CheckInBookingOverviewDto) => transformVehicleSpace(
					item,
					countType === CheckInCountType.WeightCount,
				),
			},
			{
				name: CheckInSorter.Trailer,
				className: 'trailer-length-col',
				cellClassName: 'trailer-length-cell',
				// Passing an icon required custom css for the asc/desc icon to sit nicely. See check-in.scss
				customDisplayName: <Icon name="chevron-trailer" />,
				displayName: '',
				transformItem: (item: CheckInBookingOverviewDto) => transformTrailerSpace(
					item,
					countType === CheckInCountType.WeightCount,
				) ?? '-',
				sortable: true,
				sortClicked: (): IOrderByCondition<CheckInBookingOverviewDto> => {
					return onSortClicked(
						CheckInSorter.Trailer,
						CheckInSorter.Trailer,
						CheckInSorter.TrailerDesc);
				},
			},
			{
				name: 'passengers',
				displayName: passengerTypeStore.checkInTableHeaders,
				transformItem: transformPassengers,
				nullValue: passengerTypeStore.checkInTableNullRow,
			},
		];

		if (!isVehicle) {
			const excludeColumns: string[] = [
				CheckInSorter.Rego,
				CheckInSorter.Vehicle,
				CheckInSorter.Trailer,
				CheckInSorter.VehicleType,
			];

			result = result.filter(x => !excludeColumns.includes(x.name));
		}

		if (isVehicle) {
			const excludeColumns = [
				'phone',
			];

			result = result.filter(x => !excludeColumns.includes(x.name));
		}

		if (!whiteLabelStore.config.trailersEnabled) {
			result = result.filter(x => x.name !== 'trailer-length');
		}

		return result;
	}, [
		isVehicle,
		passengerTypes.type,
		isVehicle ? cargoDescending : passengerDescending,
		isVehicle ? cargoSorting : passengerSorting,
	]);

	// The extra header count is for the action column
	const headerCount = React.useMemo(() => headers.length + 1, [headers]);

	const beforeRow = (item: CheckInBookingOverviewDto, index: number, arr: CheckInBookingOverviewDto[]): React.ReactNode => {
		if (arr.length === 0) {
			return null;
		}

		const value = comparer(item);

		if (index === 0 || value !== comparer(arr[index - 1])) {
			return (
				<tr key={`subheader-${item.id}`} className="subheader">
					<td colSpan={headerCount}>{value}</td>
				</tr>
			);
		}

		return null;
	};

	const onRowClick = (item: CheckInBookingOverviewDto) => {
		store.routerHistory.push(`/check-in/${ferryTripId}/booking/${item.id}`);
	};

	const onCheckIn = async (booking: CheckInBookingOverviewDto) => {
		if (isNotNullOrUndefined(booking)) {
			if (!booking.checkedIn) {
				await CheckInBookingConfirmationModal(
					GetModalContentForCheckIn(booking ?? undefined),
					() => checkIn(booking),
				);
			} else {
				await checkIn(booking);
			}
		}
	};
	const checkIn = async (booking: CheckInBookingOverviewDto) => {
		try {
			await checkInStore.checkInBooking(booking.id, !booking.checkedIn);
			if (booking?.checkedIn) {
				PlayCheckInAudio();
			}
		} catch (e) {
			console.error(e);
			alertToast('Check in unsuccessful', 'error');
		}
	};

	const customCheckinBtn = (booking: CheckInBookingOverviewDto) => {
		const onClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			e.stopPropagation();
			onCheckIn(booking);
		};
		return (
			<CheckInOrUndoButton
				forCheckIn={forCheckIn}
				id={booking.id}
				className={forCheckIn ? 'check-in__btn--green' : 'check-in__btn--grey'}
				onClick={onClick}
			/>
		);
	};

	// ================================================== Render =================================================== //

	const displayedBookings = filterFerryBookings({
		bookings,
		vehiclesOnly: isVehicle,
		forCheckIn,
		searchTerm: model.searchTerm,
		startStopId,
	});

	if (displayedBookings.length === 0 && stringIsEmpty(model.searchTerm)) {
		return (
			<>
				{
					forCheckIn
						? (
							<div className="all-checked-in__container">
								<AllCheckedIn />
								<If condition={showCheckInOpenClose}>
									<CheckInOpenClose
										tripId={checkInStore.ferryTripId}
										checkInClosed={checkInStore.ferryTrip.closed}
										tripDeparted={checkInStore.ferryTrip.departureDateTime < new Date()}
									/>
								</If>
							</div>
						)
						: (
							<div className="none-checked-in__container">
								<NoneCheckedIn />

								<If condition={showCheckInOpenClose}>
									<CheckInOpenClose
										tripId={checkInStore.ferryTripId}
										checkInClosed={checkInStore.ferryTrip.closed}
										tripDeparted={checkInStore.ferryTrip.departureDateTime < new Date()}
									/>
								</If>
							</div>
						)
				}
				<If condition={checkInStore.showFilters}>
					<CheckInFilters
						setRefresh={setRefresh}
						refresh={refresh}
						vehiclesOnly={isVehicle}
					/>
				</If>
			</>
		);
	}

	let comparer = (x: CheckInBookingOverviewDto) => firstLetterLastName(x).toUpperCase();
	const descending = isVehicle ? cargoDescending : passengerDescending;
	switch (isVehicle ? cargoSorting : passengerSorting) {
		case CheckInSorter.Fullname:
		case CheckInSorter.FullnameDesc:
			displayedBookings.sort(sortByFullName(descending));
			break;
		case CheckInSorter.Rego:
			comparer = (x: CheckInBookingOverviewDto) => (transformRego(x) ?? '').charAt(0);
			displayedBookings.sort(sortByRego(descending));
			break;
		case CheckInSorter.Trailer:
		case CheckInSorter.TrailerDesc:
			comparer = (x: CheckInBookingOverviewDto) => (transformTrailerSpace(
				x,
				countType === CheckInCountType.WeightCount,
			) ?? '0').toString();
			displayedBookings.sort(sortByTrailer(descending));
			break;
		case CheckInSorter.Vehicle:
		case CheckInSorter.VehicleDesc:
			comparer = (x: CheckInBookingOverviewDto) => (transformVehicleSpace(
				x,
				countType === CheckInCountType.WeightCount,
			) ?? '0').toString();
			displayedBookings.sort(sortByCar(descending));
			break;
		case CheckInSorter.VehicleType:
		case CheckInSorter.VehicleTypeDesc:
			comparer = (x: CheckInBookingOverviewDto) => (transformVehicleMakeModel(x) ?? '').charAt(0);
			displayedBookings.sort(sortByVehicleType(descending));
			break;
		default:
	}

	const updatedState = useSortSettings.getState();

	return (
		<>
			<CheckInSearch isVehicleCheckIn={isVehicle} />
			<If condition={displayedBookings.length === 0}>
				<CheckInSearchEmpty />
			</If>
			<If condition={displayedBookings.length > 0}>
				<Collection<CheckInBookingOverviewDto>
					className="check-in__table"
					collection={displayedBookings}
					headers={headers}
					beforeRow={beforeRow}
					onRowClick={onRowClick}
					actions={[
						{
							label: 'check-in',
							customButton: customCheckinBtn,
							// Action is handled in the custom button
							action: () => {},
						},
					]}
					hidePagination
					perPage={bookings.length}
					orderBy={{
						path: getCheckInTableHeader(isVehicle
							? updatedState.cargoSorting
							: updatedState.passengerSorting),
						descending: isVehicle ? updatedState.cargoDescending : updatedState.passengerDescending,
					}}
				/>
				<If condition={showCheckInOpenClose}>
					<CheckInOpenClose
						tripId={checkInStore.ferryTripId}
						checkInClosed={checkInStore.ferryTrip?.closed}
						tripDeparted={checkInStore.ferryTrip?.departureDateTime < new Date()}
					/>
				</If>
			</If>
			<If condition={checkInStore.showFilters}>
				<CheckInFilters
					setRefresh={setRefresh}
					refresh={refresh}
					vehiclesOnly={isVehicle}
				/>
			</If>
		</>
	);
}

export default observer(CheckInTable);
