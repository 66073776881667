/* eslint-disable operator-linebreak */
import { useRef } from 'react';

export const useHorizontalCenterScroll = <T extends HTMLElement>() => {
	const containerRef = useRef<T>(null);

	const centerChild = (index: number) => {
		if (!containerRef.current) return;

		const container = containerRef.current;
		const child = container.children[index] as HTMLElement;

		if (child) {
			const containerRect = container.getBoundingClientRect();
			const childRect = child.getBoundingClientRect();

			const scrollLeft =
				container.scrollLeft +
				(childRect.left - containerRect.left) -
				(containerRect.width / 2 - childRect.width / 2);

			container.scrollTo({
				left: scrollLeft,
				behavior: 'smooth',
			});
		}
	};

	return { containerRef, centerChild };
};
