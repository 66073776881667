import React from 'react';
import classNames from 'classnames';

import StopSeriesVisualiser from 'Modules/MultiStopWizard/Components/Leg/StopSeriesVisualiser';

interface StopSelectionCardProps {
	order: number;
	stopName: string;
	departureDate: string;
	departureTime: string;
	arrivalTime: string;
	arrivalExtraDays?: string;
	isSelected?: boolean;
	isFinal?: boolean;
	onClick?: () => void;
}

export default function StopSelectionCard({
	order,
	stopName,
	departureDate,
	departureTime,
	arrivalTime,
	arrivalExtraDays,
	isSelected,
	isFinal,
	onClick,
}: StopSelectionCardProps) {
	return (
		<div
			className={classNames('check-in-summary__stop-card', {
				'check-in-summary__stop-card--selected': isSelected,
			})}
			onClick={() => onClick?.()}
			//
			role="button"
			onKeyDown={() => { }}
			tabIndex={0}
		>
			<div className="check-in-summary__stop-card__title-line">
				<div className="check-in-summary__stop-name">{stopName}</div>
				{isFinal && <div className="check-in-summary__stop-order">FINAL</div>}
				{!isFinal && <div className="check-in-summary__stop-order">Stop {order}</div>}
			</div>
			{!isFinal && (
				<>
					<div className="check-in-summary__stop-card__timeline">
						<div className={classNames('check-in-summary__stop-card__time')}>{departureTime}</div>
						<StopSeriesVisualiser
							numberOfStops={0}
							variant={isSelected ? 'white' : 'contrast'}
							hideStopCountLabel
						/>
						<div className={classNames('check-in-summary__stop-card__time')}>
							{arrivalTime}
							{arrivalExtraDays && <sup>{arrivalExtraDays}</sup>}
						</div>
					</div>
					<div className="check-in-summary__stop-card__date">{departureDate}</div>
				</>
			)}
		</div>
	);
}
