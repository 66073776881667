import { observer } from 'mobx-react';
import * as React from 'react';
import {
	Redirect,
	Route,
	RouteComponentProps,
	Switch,
	useHistory,
} from 'react-router-dom';

import useAsync from 'Hooks/useAsync';
import { useCheckInRoutesWatch } from 'Hooks/useCheckInRoutesWatch';
import { CustomLocationState, isQrCodeRedirectLocationState } from 'Models/_HumanWritten/LocationState';
import { SecuredGroups } from 'Models/Security/UserGroups';
import alertToast from 'Util/ToastifyUtils';
import ScannerRoute from 'Views/Components/_HumanWritten/CheckIn/CheckInScanner/Components/ScannerRoute';
import CheckInSummary from 'Views/Components/_HumanWritten/CheckIn/FerryCheckIn/CheckInSummary/CheckInSummary';
import { useCheckInFilterEmbarkationSearchParams, useCheckInFilterSelectedStopSearchParams } from 'Views/Components/_HumanWritten/CheckIn/FerryCheckIn/Hooks/useCheckInFilterStore';
import { LottieSpinner } from 'Views/Components/_HumanWritten/Lottie/LottieSpinner';
import SecuredPage from 'Views/Components/Security/SecuredPage';
import {
	CHECK_IN_ADD_BOOKING_PAGE_TITLE,
	CHECK_IN_MOVE_BOOKING_PAGE_TITLE,
	CHECK_IN_PAGE_TITLE,
	CHECK_IN_REVIEW_BOOKING_PAGE_TITLE,
	CHECK_IN_SCAN_QR_PAGE_TITLE,
	CHECK_IN_SELECT_CUSTOMER_PAGE_TITLE,
	CHECK_IN_VIEW_BOOKING_PAGE_TITLE,
} from '../../ConstantPageNames';
import CheckInAdd from '../Components/_HumanWritten/CheckIn/FerryCheckIn/CheckInAdd/CheckInAdd';
import CheckInMove from '../Components/_HumanWritten/CheckIn/FerryCheckIn/CheckInMove/CheckInMove';
import CheckInReview from '../Components/_HumanWritten/CheckIn/FerryCheckIn/CheckInReview/CheckInReview';
import CheckInView from '../Components/_HumanWritten/CheckIn/FerryCheckIn/CheckInView';
import {
	checkInStore,
	CheckInStoreContext,
} from '../Components/_HumanWritten/CheckIn/FerryCheckIn/context/CheckInContext';
import { getRouteById } from 'Global/JourneyElements/Utils/getRouteById';

export interface CheckInRouteParams {
	/**
	 * The ferry trip entity ID
	 */
	id: string;
}

function FerryCheckInPage(props: RouteComponentProps<CheckInRouteParams>) {
	const title = window.location.href;
	if (title.includes('booking')) {
		document.title = CHECK_IN_VIEW_BOOKING_PAGE_TITLE;
	} else if (title.includes('select-customer')) {
		document.title = CHECK_IN_SELECT_CUSTOMER_PAGE_TITLE;
	} else if (title.includes('add')) {
		document.title = CHECK_IN_ADD_BOOKING_PAGE_TITLE;
	} else if (title.includes('review')) {
		document.title = CHECK_IN_REVIEW_BOOKING_PAGE_TITLE;
	} else if (title.includes('move')) {
		document.title = CHECK_IN_MOVE_BOOKING_PAGE_TITLE;
	} else if (title.includes('scan')) {
		document.title = CHECK_IN_SCAN_QR_PAGE_TITLE;
	} else {
		document.title = CHECK_IN_PAGE_TITLE;
	}

	const {
		match: {
			path, // Extract path template /check-in/:id for route
			params: { id: ferryTripId },
		},
	} = props;
	const history = useHistory();
	const locationState = history.location.state as CustomLocationState;

	const response = useAsync(() => checkInStore.loadFerryTrip(ferryTripId), [ferryTripId]);

	React.useEffect(() => {
		if (
			//
			// Is loading ferry trip complete?
			//
			response.type === 'data'
			//
			// Is previous route from QrCodeRedirectPage?
			//
			&& locationState && isQrCodeRedirectLocationState(locationState) && locationState.bookingId
		) {
			checkInStore.checkInBooking(locationState.bookingId, true);
		}
	}, [response.type, locationState]);

	useCheckInRoutesWatch();
	useCheckInFilterSelectedStopSearchParams(checkInStore.ferryTrip?.routeId);
	useCheckInFilterEmbarkationSearchParams(checkInStore.ferryTrip?.routeId);

	if (response.type === 'error') {
		alertToast('Could not find ferry trip', 'error');
		return <Redirect to="/404" />;
	}

	if (response.type === 'loading' || !checkInStore.ferryTrip) {
		return (
			<div className="body-content check-in">
				<LottieSpinner />
			</div>
		);
	}

	return (
		<SecuredPage groups={SecuredGroups.create.onlyStaff().groups}>
			<CheckInStoreContext.Provider value={checkInStore}>
				<Switch>
					<Route path={`${path}/summary`}>
						<CheckInSummary />
					</Route>
					<Route path={`${path}/add`}>
						<CheckInAdd />
					</Route>
					<Route path={`${path}/move`}>
						<CheckInMove />
					</Route>
					<Route path={`${path}/review`}>
						<CheckInReview />
					</Route>
					<Route path={`${path}/scan`}>
						<ScannerRoute />
					</Route>
					<Route path={path}>
						<CheckInView />
					</Route>
				</Switch>
			</CheckInStoreContext.Provider>
		</SecuredPage>
	);
}

export default observer(FerryCheckInPage);
