import React from 'react';

import { useHorizontalCenterScroll } from 'Hooks/Ui/useHorizontalCenterScroll';
import { useCheckInFilterStore } from '../Hooks/useCheckInFilterStore';
import { useCheckInStopSelections } from './Hooks/useCheckInStopSelections';
import StopSelectionCard from './CheckInStops/StopSelectionCard';

export default function CheckInSummaryStopSelection() {
	const { stopSelections } = useCheckInStopSelections();
	const { selectedStopId, setState } = useCheckInFilterStore();

	// Animation
	const { containerRef, centerChild } = useHorizontalCenterScroll<HTMLDivElement>();

	// Center selected stop within screen width
	React.useEffect(() => {
		const index = stopSelections.findIndex(x => x.stop.id === selectedStopId);
		if (index > -1) {
			centerChild(index);
		}
	}, [selectedStopId]);

	return (
		<div ref={containerRef} className="check-in-summary__stops-section">
			{stopSelections.map((x, index) => (
				<StopSelectionCard
					key={x.stop.id}
					order={x.stop.order}
					stopName={x.stop.location.shortName}
					departureDate={x.departureDate}
					departureTime={x.departureTime}
					arrivalTime={x.arrivalTime}
					arrivalExtraDays={x.arrivalExtraDays}
					isSelected={x.stop.id === selectedStopId}
					isFinal={index === stopSelections.length - 1}
					onClick={() => setState({ selectedStopId: x.stop.id })}
				/>
			))}
		</div>
	);
}
