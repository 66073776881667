import React from 'react';

import useCheckInStore from 'Hooks/useCheckInStore';
import CheckInSummaryRouteNav from './CheckInSummaryRouteNav';
import CheckInSummaryStopSelection from './CheckInSummaryStopSelection';
import CheckInSummaryTabs from './CheckInSummaryTabs';
import CheckInSummaryTopNav from './CheckInSummaryTopNav';

interface CheckInSummaryProps { }

export default function CheckInSummary(props: CheckInSummaryProps) {
	const { ferryTrip: { routeId, name } } = useCheckInStore();

	return (
		<div className="check-in-summary">
			<CheckInSummaryTopNav />
			<CheckInSummaryRouteNav routeId={routeId} voyageNumber={name} />
			<CheckInSummaryStopSelection />
			<div className="check-in-summary__body">
				<CheckInSummaryTabs />
			</div>
		</div>
	);
}
