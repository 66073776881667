import React from 'react';

import { whiteLabelStore } from 'Models/WhiteLabelStore';
import CheckInCargoCapacitySection from './CheckInCargoCapacitySection';
import CheckInPaxCapacitySection from './CheckInPaxCapacitySection';

/**
 * Arrange order of capacity sections based on application's booking type preference.
 */
export default function CapacityTabContent() {
	if (whiteLabelStore.defaultBookingType === 'passenger') {
		return (
			<>
				<CheckInPaxCapacitySection />
				<CheckInCargoCapacitySection />
			</>
		);
	}

	return (
		<>
			<CheckInCargoCapacitySection />
			<CheckInPaxCapacitySection />
		</>
	);
}
