import { useHistory, useLocation } from 'react-router';

export const useSearchParams = () => {
	const location = useLocation();
	const history = useHistory();

	// Helper function to GET search params
	const getSearchParam = (key: string): string | null => {
		const params = new URLSearchParams(location.search);
		return params.get(key);
	};

	// Helper function to SET search params
	const setSearchParam = (key: string, value: string) => {
		const params = new URLSearchParams(location.search);
		params.set(key, value);
		history.replace({ ...location, search: params.toString() });
	};

	return {
		getSearchParam,
		setSearchParam,
	};
};
