import * as React from 'react';
import { useState } from 'react';
import { observer } from 'mobx-react';
import useCheckInStore from 'Hooks/useCheckInStore';
import { CheckInListRow } from './CheckInListRow';
import { Flex } from 'Views/Components/Flex/Flex';
import { AllCheckedIn } from '../CheckInEmpty/AllCheckedIn';
import { CheckInListRowSubheader } from './CheckInListRowSubheader';
import { store } from 'Models/Store';
import { useCheckInRoutes } from 'Hooks/useCheckInRoutes';
import { NoneCheckedIn } from '../CheckInEmpty/NoneCheckedIn';
import { whiteLabelStore } from 'Models/WhiteLabelStore';
import { CheckInOpenClose } from './CheckInOpenClose';
import { CheckInBookingOverviewDto } from '../CheckInEntities/CheckInBookingOverviewDto';
import { CheckInFilters } from '../CheckInFilters';
import If from 'Views/Components/If/If';
import {
	CheckInCountType, useFilterAddOn,
	useSearchTerm,
	useSortSettings,
} from '../CheckInView';
import { stringIsEmpty } from 'Util/TypeGuards';
import { CheckInSearch } from '../CheckInSearch';
import { CheckInSearchEmpty } from '../CheckInTable/CheckInSearchEmpty';
import { filterFerryBookings } from '../Helpers/FilterFerryBookings';
import {
	CheckInSorter,
	firstLetterLastName,
	sortByCar,
	sortByFullName,
	sortByRego,
	sortByTrailer,
	sortByVehicleType,
	transformRego,
	transformTrailerSpace,
	transformVehicleMakeModel,
	transformVehicleSpace,
} from '../CheckInUtils';

export interface CheckInListProps {
	vehiclesOnly?: boolean;
	forCheckIn?: boolean;
	startStopId?: string;
	endStopId?: string;
	embarkation?: 'embark' | 'disembark';
}

function CheckInList(props: CheckInListProps) {
	const {
		forCheckIn = false,
		vehiclesOnly = false,
		startStopId,
		endStopId,
		embarkation,
	} = props;
	console.log(embarkation);

	const {
		countType,
		cargoSorting,
		cargoDescending,
		passengerSorting,
		passengerDescending,
	} = useSortSettings(x => ({
		countType: x.countType,
		cargoSorting: x.cargoSorting,
		cargoDescending: x.cargoDescending,
		passengerSorting: x.passengerSorting,
		passengerDescending: x.passengerDescending,
	}));

	const {
		searchTermModel,
	} = useSearchTerm(x => ({
		searchTermModel: x.model,
	}));

	const {
		addOnsModel,
	} = useFilterAddOn(x => ({
		addOnsModel: x.model,
	}));

	const [refresh, setRefresh] = useState<number>(1);

	const checkInStore = useCheckInStore();
	const { bookings, isOffline } = checkInStore;
	const routes = useCheckInRoutes();

	const showCheckInOpenClose = !isOffline();

	const displayedBookings = filterFerryBookings({
		bookings,
		vehiclesOnly,
		forCheckIn,
		searchTerm: searchTermModel.searchTerm,
		embarkation,
		startStopId,
		endStopId,
	});

	if (displayedBookings.length === 0 && stringIsEmpty(searchTermModel.searchTerm)) {
		return (
			<>
				{
					forCheckIn
						? (
							<div className="all-checked-in__container">
								<AllCheckedIn />
								<If condition={showCheckInOpenClose}>
									<CheckInOpenClose
										tripId={checkInStore.ferryTripId}
										checkInClosed={checkInStore.ferryTrip.closed}
										tripDeparted={checkInStore.ferryTrip.departureDateTime < new Date()}
									/>
								</If>
							</div>
						)
						: (
							<div className="none-checked-in__container">
								<NoneCheckedIn />
								<If condition={showCheckInOpenClose}>
									<CheckInOpenClose
										tripId={checkInStore.ferryTripId}
										checkInClosed={checkInStore.ferryTrip.closed}
										tripDeparted={checkInStore.ferryTrip.departureDateTime < new Date()}
									/>
								</If>
							</div>
						)
				}
				<If condition={checkInStore.showFilters}>
					<CheckInFilters
						setRefresh={setRefresh}
						refresh={refresh}
						vehiclesOnly={vehiclesOnly}
					/>
				</If>
			</>
		);
	}

	let comparer = (x: CheckInBookingOverviewDto) => firstLetterLastName(x).toUpperCase();
	const descending = vehiclesOnly ? cargoDescending : passengerDescending;
	switch (vehiclesOnly ? cargoSorting : passengerSorting) {
		case CheckInSorter.Fullname:
		case CheckInSorter.FullnameDesc:
			displayedBookings.sort(sortByFullName(descending));
			break;
		case CheckInSorter.Rego:
			comparer = (x: CheckInBookingOverviewDto) => (transformRego(x) ?? '').charAt(0);
			displayedBookings.sort(sortByRego(descending));
			break;
		case CheckInSorter.Trailer:
		case CheckInSorter.TrailerDesc:
			comparer = (x: CheckInBookingOverviewDto) => (transformTrailerSpace(
				x,
				countType === CheckInCountType.WeightCount,
			) ?? '0').toString();
			displayedBookings.sort(sortByTrailer(descending));
			break;
		case CheckInSorter.Vehicle:
		case CheckInSorter.VehicleDesc:
			comparer = (x: CheckInBookingOverviewDto) => (transformVehicleSpace(
				x,
				countType === CheckInCountType.WeightCount,
			) ?? '0').toString();
			displayedBookings.sort(sortByCar(descending));
			break;
		case CheckInSorter.VehicleType:
		case CheckInSorter.VehicleTypeDesc:
			comparer = (x: CheckInBookingOverviewDto) => (transformVehicleMakeModel(x) ?? '').charAt(0);
			displayedBookings.sort(sortByVehicleType(descending));
			break;
		default:
	}

	return (
		<>
			<Flex direction="col" className="check-in-list" flex={1}>
				<CheckInSearch isVehicleCheckIn={vehiclesOnly} />
				<If condition={displayedBookings.length === 0}>
					<CheckInSearchEmpty />
				</If>
				<If condition={displayedBookings.length > 0}>
					{
						displayedBookings.map((item, index, arr) => {
							return (
								<React.Fragment key={item.id}>
									<CheckInListRowSubheader
										item={item}
										index={index}
										arr={arr}
										comparer={comparer}
									/>
									<CheckInListRow
										key={item.id}
										booking={item}
										showVehicles={vehiclesOnly}
										forCheckIn={forCheckIn}
										onClick={() => {
											routes.goToEdit(item.id);
										}}
									/>
								</React.Fragment>
							);
						})
					}
					<If condition={showCheckInOpenClose}>
						<CheckInOpenClose
							tripId={checkInStore.ferryTripId}
							checkInClosed={checkInStore.ferryTrip.closed}
							tripDeparted={checkInStore.ferryTrip.departureDateTime < new Date()}
						/>
					</If>
				</If>
				<If condition={checkInStore.showFilters}>
					<CheckInFilters
						setRefresh={setRefresh}
						refresh={refresh}
						vehiclesOnly={vehiclesOnly}
					/>
				</If>
			</Flex>
		</>
	);
}

export default observer(CheckInList);

export function getCheckInSortOptions(sortByWeight: boolean = false) {
	const sortOptions = [
		{ text: 'Name (A - Z)', value: CheckInSorter.Fullname },
		{ text: 'Name (Z - A)', value: CheckInSorter.FullnameDesc },
		{
			text: `Highest ${whiteLabelStore.vehicleLabelLowerCase} ${sortByWeight ? 'weight' : 'length'}`,
			value: CheckInSorter.VehicleDesc,
		},
		{
			text: `Lowest ${whiteLabelStore.vehicleLabelLowerCase} ${sortByWeight ? 'weight' : 'length'}`,
			value: CheckInSorter.Vehicle,
		},
		{ text: `${whiteLabelStore.vehicleLabelPascalCase} type (A - Z)`, value: CheckInSorter.VehicleType },
		{ text: `${whiteLabelStore.vehicleLabelPascalCase} type (Z - A)`, value: CheckInSorter.VehicleTypeDesc },
	];

	if (whiteLabelStore.config.trailersEnabled) {
		sortOptions.unshift(...[
			{ text: 'Highest tow on length', value: CheckInSorter.TrailerDesc },
			{ text: 'Lowest tow on length', value: CheckInSorter.Trailer },
		]);
	}

	sortOptions.unshift(...[
		{ text: whiteLabelStore.regoLabelPascalCase, value: CheckInSorter.Rego },
	]);

	return sortOptions;
}

export function getCheckInCountTypeOptions() {
	return [
		{ text: 'Spaces', value: CheckInCountType.SpaceCount },
		{ text: 'Items', value: CheckInCountType.ItemCount },
		{ text: 'Weight', value: CheckInCountType.WeightCount },
	];
}
